import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Products/CarouselContent.module.scss'


const CarouselContentEderly = () => {
  return (
    <div className={styles.content}>
      <h2>Smart Elderly Care Kit/Sensor</h2>
      <p>
      Proactive Health Monitoring: Continuous monitoring of vital signs and activity levels, ensuring timely intervention in case of health issues
      </p>
      <Link to="/">BUY NOW</Link>
      
    </div>
  )
}

export default CarouselContentEderly
