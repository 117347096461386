import React from 'react'

const Material = () => {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.7917 2.04565H10.2083C7.9625 2.04565 6.14542 3.88315 6.14542 6.12899L6.125 32.5277C6.125 33.9365 6.83958 35.1819 7.92167 35.9169L23.3771 46.2069C24.0713 46.6561 24.9492 46.6561 25.6433 46.2069L41.0783 35.9169C42.1604 35.1819 42.875 33.9365 42.875 32.5277V6.12899C42.875 3.88315 41.0375 2.04565 38.7917 2.04565ZM37.3625 15.7248L21.8663 31.2211C21.07 32.0173 19.7838 32.0173 18.9875 31.2211L11.6579 23.8915C10.8617 23.0952 10.8617 21.809 11.6579 21.0127C12.4542 20.2165 13.7404 20.2165 14.5367 21.0127L20.4167 26.8927L34.4633 12.8461C35.2596 12.0498 36.5458 12.0498 37.3421 12.8461C38.1383 13.6423 38.1587 14.9286 37.3625 15.7248Z" fill="#CC2500"/>
    </svg>
  )
}

export default Material
