import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/medicine.svg'

const Service6= () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Smart Elderly/Realtime Patient care IOT" fontSize="1.5rem" />
        <p>
        Smart elderly/Realtime patient care hardware solutions enable them to live independently at home for longer periods of time. It can detect the life trajectory of the elderly and can also protect the safety of the elderly. It is used to Monitor and also send Notification and also gives a preventive measure rather than Emergency situation.
        </p>
      </div>
    </div>
  )
}

export default Service6
