import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Home/Subsidiary.module.scss'
import SectionHeader from '../SectionHeader'
import grandeurSmart from '../../assets/images/grandeur_smartLogo.png'
import intelkard from '../../assets/images/intelkardLogo.png'
import hubtecs from '../../assets/images/hubtecsLogo.png'
import smart from '../../assets/images/Smart by Mainlogix logo.png'

const Subsidiary = () => {
  return (
    <div className={styles.subsidiary}>
      <SectionHeader text="Our Subsidiary" />
      <div className={styles.subsidiaryLogos}>
        <a href='https://grandeursmart.com/'>
          <img src={grandeurSmart} alt="grandeur smart" />
        </a>
        <a href='https://intelkard.com/'>
          <img src={intelkard} alt="intelkard" />
        </a>
        <a href='/'>
          <img src={smart} width='130px' alt="smart" />        
        </a>
      </div>
    </div>
  )
}

export default Subsidiary
