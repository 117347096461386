import React from 'react'

const Twitter = () => {
  return (
    <svg width="15" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.9599 2.68051C25.9758 3.11657 24.9187 3.4112 23.8073 3.54437C24.954 2.85822 25.8119 1.77831 26.221 0.506123C25.1436 1.14605 23.9645 1.59649 22.7349 1.83786C21.908 0.954984 20.8128 0.369798 19.6192 0.173158C18.4257 -0.0234827 17.2006 0.179424 16.1342 0.750376C15.0678 1.32133 14.2197 2.22838 13.7217 3.33071C13.2236 4.43304 13.1034 5.66897 13.3797 6.84661C11.1967 6.737 9.06112 6.16961 7.11159 5.18124C5.16207 4.19287 3.44214 2.80562 2.06345 1.10953C1.59204 1.92272 1.32098 2.86554 1.32098 3.86965C1.32045 4.77357 1.54305 5.66365 1.96903 6.46092C2.395 7.25818 3.01118 7.93798 3.76289 8.43999C2.8911 8.41225 2.03855 8.17668 1.27619 7.7529V7.82361C1.27611 9.09141 1.71464 10.3202 2.5174 11.3015C3.32016 12.2827 4.43768 12.956 5.68036 13.2071C4.87163 13.426 4.02374 13.4582 3.20073 13.3014C3.55134 14.3923 4.2343 15.3462 5.15399 16.0296C6.07368 16.7131 7.18407 17.0918 8.32969 17.1128C6.38492 18.6395 3.98314 19.4676 1.51072 19.464C1.07276 19.4641 0.635163 19.4385 0.200195 19.3873C2.70985 21.001 5.63126 21.8573 8.6149 21.854C18.7149 21.854 24.2363 13.4888 24.2363 6.23378C24.2363 5.99807 24.2304 5.76001 24.2198 5.5243C25.2938 4.74762 26.2208 3.78585 26.9575 2.68405L26.9599 2.68051Z" />
    </svg>
  )
}

export default Twitter
