import React from 'react'
import styles from '../../sass/components/Services/TopServices.module.scss'
import SectionHeader from '../SectionHeader'
import productItemOne from '../../assets/images/productItemOne.png'

const TopServices = () => {
  return (
    <div className={styles.topServices}>
      <SectionHeader text="Top Services" />
      <p className={styles.description}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidd exerci 
      </p>
      <div className={styles.services}>
        <div className={styles.service}>
          <p className={styles.tag}>NEW</p>
          <img src={productItemOne} alt="service name" />
        </div>
        <div className={styles.service}>
          <p className={styles.tag}>NEW</p>
          <img src={productItemOne} alt="service name" />
        </div>
        <div className={styles.service}>
          <p className={styles.tag}>NEW</p>
          <img src={productItemOne} alt="service name" />
        </div>
        <div className={styles.service}>
          <p className={styles.tag}>NEW</p>
          <img src={productItemOne} alt="service name" />
        </div>
      </div>
    </div>
  )
}

export default TopServices
