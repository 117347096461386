import React from 'react'

const ArrowUp = ({onClick}) => {
  return (
    <svg onClick={onClick} width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5 22.4819L15 13.4891L7.5 22.4819" stroke="#CC2500" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowUp
