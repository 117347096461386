import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Products/CarouselContent.module.scss'


const CarouselContent = () => {
  return (
    <div className={styles.content}>
      <h2>Smart Home Automation Kit</h2>
      <p>
        Solutions for controlling and automating home devices via mobile phones.
        This includes lighting, heating, coolin, and appliance control, enhancing convenience and efficiency.
      </p>
      <Link to="/">BUY NOW</Link>
      
    </div>
  )
}

export default CarouselContent
