import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/transport.svg'

const Service7 = () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Logistics IoT" fontSize="1.5rem" />
        <p>
        Transportation is an essential part of human reality.Mainlogix IoT solution is streamlining logistics processes with Smart systems that can remotely control elements of the transportation process
        </p>
      </div>
    </div>
  )
}

export default Service7
