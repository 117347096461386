import React from 'react'
import styles from '../sass/pages/Home.module.scss'
import Hero from '../components/Home/Hero'
import Services from '../components/Home/Services'
import Subsidiary from '../components/Home/Subsidiary'
// import SubsidiaryProduct from '../components/Home/SubsidiaryProduct'
import Partners from '../components/Home/Partners'
import BlogSnippet from '../components/Home/BlogSnippet'
import Footer from '../components/Footer'
import Products from '../components/Home/Products'

const Home = () => {

  
  return (
    <main className={styles.home}>
      <Hero />
      <Services />
      <Subsidiary />
      {/* <SubsidiaryProduct /> */}
      <Products />
      <Partners />
      <BlogSnippet />
      <Footer />
    </main>
  )
}

export default Home
