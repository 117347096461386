import React from 'react'
import styles from '../../sass/components/About/Mission.module.scss'
import SectionHeader from '../SectionHeader'
import achievement from '../../assets/images/achievement.jpg'
import achievement2 from '../../assets/images/achievement2.jpg'

const Awards = () => {
  return (
    <div className={styles.mission}>
      <SectionHeader text="Awards and Recognitions" fontSize="1.5rem" />
      <div className={styles.missionContent}>
        <div className={styles.description}>
            <p>
            <li> Forbes 30 Under 30 for 2024 </li>
            <li> Breakthrough Longevity Activism Prize</li>
            <li> Multiple grants and accolades from industry leaders and foundations.</li>

            </p>
        
            
        </div>
        <div className={styles.images}>
          <img src={achievement2} alt="award" />
          <img src={achievement} alt="award" />
        </div>
      </div>
    </div>
  )
}

export default Awards
