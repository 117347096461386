import React from 'react'

const Link = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.375 15C8.37968 16.3853 8.55318 17.7649 8.89167 19.1083H14.2917V11.2083H8.81667C8.5286 12.4518 8.38046 13.7236 8.375 15Z" fill="#CC2500"/>
      <path d="M15.7085 19.1166H21.1085C21.4617 17.7723 21.6492 16.3898 21.6668 15C21.6641 13.7239 21.5188 12.4521 21.2335 11.2083H15.7085V19.1166Z" fill="#CC2500"/>
      <path d="M27.6002 10.675C27.0964 10.9062 26.5638 11.0687 26.0168 11.1583H25.8335C25.2619 11.2468 24.6801 11.2468 24.1085 11.1583H22.6085C22.8915 12.4196 23.0395 13.7074 23.0502 15C23.0459 16.3829 22.8865 17.761 22.5752 19.1083H25.9085C25.7142 19.627 25.483 20.131 25.2168 20.6167H22.1668C21.5964 22.4023 20.7531 24.089 19.6668 25.6167C18.8652 25.966 18.0265 26.2232 17.1668 26.3833C18.7064 24.7104 19.8913 22.7431 20.6502 20.6H15.7168V26.5583H14.3085V20.5917H9.35016C10.108 22.7378 11.293 24.708 12.8335 26.3833C11.9915 26.225 11.1698 25.9734 10.3835 25.6333C9.29335 24.0928 8.4499 22.3919 7.88349 20.5917H4.81683C4.55061 20.106 4.31947 19.602 4.12516 19.0833H7.45849C7.14615 17.7444 6.98404 16.3748 6.97516 15C6.97562 13.7256 7.10969 12.4548 7.37516 11.2083H3.96683C4.14208 10.6912 4.35372 10.1872 4.60016 9.70001H7.77516C8.3709 7.71587 9.2948 5.84555 10.5085 4.16667C11.2781 3.84777 12.08 3.61307 12.9002 3.46667C11.2424 5.24588 9.99126 7.36425 9.23349 9.67501H14.3085V3.33334H15.7168V9.70001H20.7918V9.59167C19.6723 8.55513 18.9725 7.14356 18.8252 5.62501C18.3269 4.87353 17.7696 4.16295 17.1585 3.50001C17.7416 3.61236 18.3154 3.76835 18.8752 3.96667C18.965 3.43144 19.1247 2.91032 19.3502 2.41667C16.4887 1.42726 13.3764 1.43732 10.5214 2.44521C7.66643 3.45309 5.23757 5.39924 3.63149 7.9658C2.02542 10.5324 1.33706 13.5677 1.67882 16.576C2.02059 19.5843 3.37229 22.3878 5.51317 24.5287C7.65404 26.6695 10.4576 28.0212 13.4659 28.363C16.4742 28.7048 19.5095 28.0164 22.076 26.4103C24.6426 24.8043 26.5887 22.3754 27.5966 19.5204C28.6045 16.6655 28.6146 13.5531 27.6252 10.6917L27.6002 10.675Z" fill="#CC2500"/>
      <path d="M25.0002 9.16665C27.3014 9.16665 29.1668 7.30117 29.1668 4.99998C29.1668 2.69879 27.3014 0.833313 25.0002 0.833313C22.699 0.833313 20.8335 2.69879 20.8335 4.99998C20.8335 7.30117 22.699 9.16665 25.0002 9.16665Z" fill="#CC2500"/>
    </svg>
  )
}

export default Link
