import React from 'react'
import { useState } from 'react'
import styles from '../sass/components/Accordion.module.scss'
import Pane from './Pane'

const Accordion = ({faqs}) => {

  const [current, setCurrent] = useState("")
  const initialState = faqs.map( faq => "0")
  const [height, setHeight] = useState(initialState)


  return (
    <div className={styles.accordion}>
      {faqs.map((faq, index) => (
        <Pane key={index} info={{faq, index, height, setHeight, current, setCurrent}} />
      ))}
    </div>
  )
}

export default Accordion
