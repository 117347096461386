import React from 'react'
import styles from '../sass/pages/Contact.module.scss'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Location from '../assets/icons/Location'
import Mail from '../assets/icons/Mail'
import Phone from '../assets/icons/Phone'
import Link from '../assets/icons/Link'

const Contact = () => {
  const headerStyles = {
    width: "100%",
  }

  return (
    <div className={styles.contact}>
      <div className={styles.hero}>
        <Header linkStyles={{color: "white"}} externalStyles={headerStyles}/>
        <h2>Contact Us</h2>
      </div>
      <div className={styles.contactInformation}>
        <div className={styles.contactLinks}>
          <div className={styles.contactLink}>
            <Location />
            <h5>ADDRESS</h5>
            <a href="https://goo.gl/maps/9x7xYLWNyscnVgAn9">Suite 1, 315 Herbert Macaulay Rd, Yaba, Lagos</a>
          </div>
          <div className={styles.contactLink}>
            <Phone />
            <h5>PHONE</h5>
            <a href="tel:+2348062081641">+2348062081641</a>
            <a href="tel:+2347066033120">+2347066033120</a>
          </div>
          <div className={styles.contactLink}>
            <Mail />
            <h5>EMAIL</h5>
            <a href="mailto:info@mainlogix.org">info@mainlogix.io</a>
            <a href="mailto:mainlogixlimited@gmail.com">mainlogixlimited@gmail.com</a>
          </div>
          <div className={styles.contactLink}>
            <Link />
            <h5>WEBSITE</h5>
            <a href=" https://mainlogix.com"> https://mainlogix.io</a>
          </div>
        </div>
        <form className={styles.contactForm}>
          <h3>Contact Us</h3>
          <input type="text" name="name" placeholder="Enter your name" />
          <input type="email" name="email" placeholder="Enter your email address" />
          <textarea name="message" placeholder="Your Message..." cols="30" rows="10" />
          <button type="submit">Submit</button>
        </form>
      </div>
      <iframe
        className={styles.location}
        title="location"
        // Construct the new URL with your desired address parameters
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.26183039624!2d3.3559440141793457!3d6.614356395216831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93b65ba86e15%3A0x3ae060fb3436c3ad!2s1,+315+Herbert+Macaulay+Wy,+Alagomeji-Yaba,+Lagos+101245,+Lagos+State,+Nigeria!5e0!3m2!1sen!2s!4v1637340940559!5m2!1sen!2s`}
        height="450"
        allowFullScreen=""
        loading="lazy"
      />

      <Footer />
    </div>
  )
}

export default Contact
