import React from 'react'

const Facebook = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.606 16.6225V10.134H12.7949L13.1203 7.59356H10.606V5.97539C10.606 5.24231 10.8102 4.74039 11.8623 4.74039H13.1955V2.47543C12.5468 2.40592 11.8948 2.37235 11.2425 2.37489C9.30763 2.37489 7.97921 3.55606 7.97921 5.72443V7.58881H5.8045V10.1293H7.98396V16.6225H10.606Z" />
    </svg>
  )
}

export default Facebook
