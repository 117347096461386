import React from 'react'
import styles from '../../sass/components/Products/TopSellingProduct.module.scss'
import productItemOne from '../../assets/images/IMG_6987.PNG'
import { Link } from 'react-router-dom'

const TopSellingProduct4 = () => {
  return (
    <div className={styles.product}>
      <img src={productItemOne} alt="product item" />
      <div className={styles.productDescription}>
        <h3>Attendace System</h3>
        <p>Efficient and Secure Employee Attendance Management</p>
        <p>$149.99</p>
        <Link to="/">BUY NOW</Link>
      </div>
    </div>
  )
}

export default TopSellingProduct4
