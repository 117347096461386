import React from 'react'

const Border = ({className}) => {
  return (
    <svg className={className} width="116" height="126" viewBox="0 0 116 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6522 24.8247L1 2V125H114L94.3478 104.711H20.6522V24.8247Z" />
    </svg>
  )
}

export default Border
