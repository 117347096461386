import React from 'react'
import styles from '../../sass/components/ProductDetail/HowTo.module.scss'
import alarmKitDoorbell from '../../assets/images/alarmKitDoorbell.jpg'
import SectionHeader from '../SectionHeader'

const HowTo = () => {
  return (
    <div className={styles.howTo}>
      <div className={styles.howToItem}>
        <div className={styles.desc}>
          <SectionHeader text="How to use" fontSize="1.5rem" />
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio
          </p>
        </div>
        <div className={styles.image}>
          <img src={alarmKitDoorbell} alt="alarm kit doorbell" />
        </div>
      </div>
      <div className={styles.howToItem}>
        <div className={styles.desc}>
          <SectionHeader text="How to install" fontSize="1.5rem" />
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio
          </p>
        </div>
        <div className={styles.image}>
          <img src={alarmKitDoorbell} alt="alarm kit doorbell" />
        </div>
      </div>
    </div>
  )
}

export default HowTo
