import React from 'react'
import styles from '../sass/pages/Feedback.module.scss'
import logo from '../assets/images/Logo.png'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Star from '../assets/icons/Star'

const Feedback = () => {
  return (
    <div className={styles.join}>
      <div className={styles.colOne}>
        <header className={styles.header}>
          <Link to="/"> <img src={logo} alt="mainlogix logo" /> </Link>
        </header>
        <div className={styles.colOneContent}>
          <h5>Feel free to drop us a line & give us your feedback</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipisc
          </p>
        </div>
      </div>
      <div className={styles.colTwo}>
        <div className={styles.mobileContent}>
          <Header />
          <h5>Feel free to drop us a line & give us your feedback</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipisc
          </p>
        </div>
        <div className={styles.colTwoContent}>
          <div>
            <p>1- How many of our offered products/ services do you currently use?</p>
            <select className={styles.quantity} name="quantity" id="">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div>
            <p>2- Are you happy with our product/ service reliability and performance?</p>
            <div className={styles.radio}>
              <label htmlFor="yes">Yes</label>
              <input type="radio" name="yes" id="" />
              <label htmlFor="no">No</label>
              <input type="radio" name="no" id="" />
            </div>
          </div>
          <div>
            <p>3-  How will you rate our overall quality of packaging?</p>
            <div className={styles.stars}>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
          </div>
          <div>
            <p>4- How will you rate your overall customer experience?</p>
            <div className={styles.stars}>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
          </div>
          <div>
            <p>5- Would you recommend our products or services to someone?</p>
            <div className={styles.radio}>
              <label htmlFor="yes">Yes</label>
              <input type="radio" name="yes" id="" />
              <label htmlFor="no">No</label>
              <input type="radio" name="no" id="" />
            </div>
          </div>
          <div>
            <p>6- Please provide below any additional comments or suggestions?</p>
            <textarea className={styles.textarea} placeholder="Write something..." name="suggestion" id="" cols="30" rows="10"/>
          </div>
          <button className={styles.submit}>SUBMIT</button>
        </div>

      </div>
    </div>
  )
}

export default Feedback
