import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Home/Products.module.scss'
import agricSlide from '../../assets/images/agricSlider.jpg'
import medicineSlide from '../../assets/images/medicineSlider.jpg'
import homeSlide from '../../assets/images/homeSlider.jpg'
import growth from '../../assets/images/growth.jpg'
import intelkardPlasticWhite from '../../assets/images/intelkardPlasticWhite.png'
import intelkardWooden from '../../assets/images/intelkardWooden.png'
import SectionHeader from '../SectionHeader'
import Carousel from '../Carousel'

const Products = () => {

  const slideStyle ={
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundColor: "grey",
    backgroundBlendMode: "multiply"
  }

  const mainlogixCarouselData = [
    {
      imgSrc: agricSlide,
      content: <>           
          <h3>Mainlogix Product One</h3>
          <Link to="/products">GO TO SHOP</Link> 
        </>,
      slideStyle
    },
    {
      imgSrc: medicineSlide,
      content: <>           
          <h3>Mainlogix Product Two</h3>
          <Link to="/products">GO TO SHOP</Link> 
        </>,
      slideStyle
    }
  
  ]

  const intelkardCarouselData = [
    {
      imgSrc: intelkardPlasticWhite,
      content: <>           
          <h3>Intelkard Product One</h3>
          <a href='https://intelkard.com/'>GO TO SHOP</a> 
        </>,
      slideStyle
    },
    {
      imgSrc: intelkardWooden,
      content: <>           
          <h3>Intelkard Product Two</h3>
          <a href='https://intelkard.com/'>GO TO SHOP</a> 
        </>,
      slideStyle
    }
  
  ]

  const grandeurSmartCarouselData = [
    {
      imgSrc: homeSlide,
      content: <>           
          <h3>Grandeur Smart Product One</h3>
          <a href='https://grandeursmart.com/'>GO TO SHOP</a> 
        </>,
      slideStyle
    },
    {
      imgSrc: growth,
      content: <>           
          <h3>Grandeur Smart Product Two</h3>
          <a href='https://grandeursmart.com/'>GO TO SHOP</a>  
        </>,
      slideStyle
    }
  
  ]
  return (
    <div className={styles.products}>
      <SectionHeader text="Our Products" />
      <div className={styles.productList}>
        <div className={styles.product}>
          <Carousel carouselData={mainlogixCarouselData} controls={{type:"in"}} />
        </div>
        <div className={styles.product}>
          <Carousel carouselData={intelkardCarouselData} controls={{type:"in"}} />
        </div>
        <div className={styles.product}>
          <Carousel carouselData={grandeurSmartCarouselData} controls={{type:"in"}} />
        </div>
        {/* <div className={styles.product} style={{backgroundImage: `url(${agricSlide})`}}>
          <h3>Product One</h3>
          <Link to="/products">GO TO SHOP</Link>
        </div>
        <div className={styles.product} style={{backgroundImage: `url(${medicineSlide})`}}>
          <h3>Product Two</h3>
          <Link to="/products">GO TO SHOP</Link>
        </div>
        <div className={styles.product} style={{backgroundImage: `url(${intelkardPlasticWhite})`}}>
          <h3>Product Three</h3>
          <Link to="/products">GO TO SHOP</Link>
        </div> */}
      </div>
    </div>
  )
}

export default Products
