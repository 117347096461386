import React from 'react'

const Phone = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.25 12.1875H24.375C24.373 10.4476 23.681 8.77958 22.4507 7.5493C21.2204 6.31903 19.5524 5.62699 17.8125 5.625V3.75C20.0495 3.75248 22.1942 4.64223 23.776 6.22403C25.3578 7.80583 26.2475 9.9505 26.25 12.1875Z" fill="#CC2500"/>
      <path d="M19.0623 20.1394L21.1623 18.0394C21.4434 17.7582 21.8008 17.5657 22.1902 17.4857C22.5797 17.4057 22.984 17.4418 23.3532 17.5894L25.9107 18.6131C26.2877 18.764 26.6108 19.0243 26.8384 19.3605C27.066 19.6967 27.1876 20.0934 27.1876 20.4994V25.1503C27.1882 25.4267 27.1325 25.7004 27.0237 25.9545C26.9149 26.2087 26.7554 26.4379 26.555 26.6283C26.3545 26.8186 26.1174 26.9661 25.8579 27.0616C25.5985 27.1571 25.3224 27.1987 25.0463 27.1838C7.11945 26.0681 3.49883 10.8844 2.82664 5.07001C2.79409 4.78575 2.82208 4.49783 2.90878 4.22517C2.99548 3.95251 3.13892 3.70129 3.32968 3.48805C3.52043 3.2748 3.75417 3.10436 4.01552 2.98792C4.27687 2.87149 4.5599 2.81171 4.84602 2.81251H9.41445C9.82046 2.81249 10.2172 2.93413 10.5534 3.16173C10.8896 3.38932 11.1499 3.71243 11.3007 4.08938L12.3235 6.64688C12.4711 7.01605 12.5072 7.42041 12.4272 7.80985C12.3472 8.1993 12.1547 8.55672 11.8735 8.83782L9.77352 10.9378C9.77352 10.9378 10.9379 19.1241 19.0623 20.1394Z" fill="#CC2500"/>
    </svg>
  )
}

export default Phone
