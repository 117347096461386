import React from 'react'
import styles from '../../sass/components/Products/ProductItem.module.scss'
import productItemOne from '../../assets/images/Grandeur_smart_home_auto.png'
import { Link } from 'react-router-dom'
const ProductItem = () => {
  return (
    <div className={styles.productItem}>
      <div className={styles.productDescription}>
        <h3>Smart Home Automation Kit</h3>
        <p>Solutions for controlling and automating home devices via mobile phones. </p>
        <Link to="/">BUY NOW</Link>
      </div>
      <img src={productItemOne} alt="product item" />
    </div>
  )
}

export default ProductItem
