import React from 'react'
import styles from '../../sass/components/Blog/PopularBlogs.module.scss'
import growth from '../../assets/images/growth.jpg'

const PopularBlogs = () => {
  return (
    <div className={styles.popularBlogs}>
      <h3>Popular Posts</h3>
      <div className={styles.blogs}>
        <div className={styles.blog}>
          <div className={styles.blogImage}>
            <img src={growth} alt="growth" />
          </div>
          <div className={styles.blogContent}>
            <h4>The Internet of Things in Solutions Architecture</h4>
            <p>
            Information modeling and event management are critical for detecting changes across complex industrial systems. Industrial IoT solutions can address this.
            </p>
            <hr />
            <span>Saurabh Shrivastava · Apr. 09, 22</span>
          </div>
        </div>
        <div className={styles.blog}>
          <div className={styles.blogImage}>
            <img src={growth} alt="growth" />
          </div>
          <div className={styles.blogContent}>
            <h4>Cellular Tech Enables Next-Gen Transportation</h4>
            <p>
              There is no doubt that connected devices and technologies are transforming how humans and machines work together in places like operating rooms, factories, and roadways/intersections
            </p>
            <hr />
            <span>Apr 20, 2022 Connected World</span>
          </div>
        </div>
        <div className={styles.blog}>
          <div className={styles.blogImage}>
            <img src={growth} alt="growth" />
          </div>
          <div className={styles.blogContent}>
            <h4>Audi’s Urbansphere: The Autonomous Car Cabin of the Future</h4>
            <p>
            Audi has unveiled its latest vision of what Level 4 autonomous transport might look like – and it’s probably not what you might expect.
            </p>
            <hr />
            <span>Graham Hope 20th April 2022</span>
          </div>
        </div>
        <div className={styles.blog}>
          <div className={styles.blogImage}>
            <img src={growth} alt="growth" />
          </div>
          <div className={styles.blogContent}>
            <h4>Unveiling the Potential Relationship between IoT and Cloud Computing</h4>
            <p>
            Today, if we look around, we find that IoT, the Internet of Things, disrupts our daily lives, either at home or the workplace. It has been 20 years since this concept has knocked the tech world.
            </p>
            <hr />
            <span>Graham Hope 12 April 2021</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopularBlogs
