import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Home/ServiceCarouselContent.module.scss'

const ServiceCarouselContent = ({data}) => {
  return (
    <div className={styles.content}>
      <img src={data.illustration} alt="" />
      <header className={styles.header}>
        <h4>{data.industry}</h4>
        <hr />
      </header>
      <p>{data.description}</p>
      <Link to={data.path}>{">"}</Link>
    </div>
  )
}

export default ServiceCarouselContent
