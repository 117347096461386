import React from 'react'
import styles from '../sass/pages/JoinUs.module.scss'
import logo from '../assets/images/Logo.png'
import { Link } from 'react-router-dom'
import Header from '../components/Header'

const JoinUs = () => {
  return (
    <div className={styles.join}>
      <div className={styles.colOne}>
        <header className={styles.header}>
          <Link to="/"> <img src={logo} alt="mainlogix logo" /> </Link>
        </header>
        <div className={styles.colOneContent}>
          <h5>Become a partner</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
          </p>
        </div>
      </div>
      <div className={styles.colTwo}>
        <div className={styles.mobileContent}>
          <Header />
          <h5>Become a partner</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
          </p>
        </div>
        <div className={styles.colTwoContent}>
          <form className={styles.form}>
            <input type="text" name="name" placeholder="name" />
            <input type="email" name="email" placeholder="Email" />
            <input type="tel" name="phone" placeholder="Phone" />
            <input type="text" name="company" placeholder="Company/Business name" />
            <input type="text" name="website" placeholder="Company website" />
            <button type="submit">JOIN</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default JoinUs
