import React from 'react'

const ArrowDown = ({onClick}) => {
  return (
    <svg onClick={onClick} width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.61279 14.3063L15.8898 23.4805L23.6083 14.6746" stroke="#CC2500" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowDown
