import React from 'react'

const Manufacturer = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.1889 16.2701C32.9443 15.0268 31.3339 14.2122 29.5333 14.2122H22.4664C22.4482 14.2122 22.4313 14.217 22.4132 14.2175L22.396 14.2149C22.1572 14.2038 19.9267 14.1562 17.8109 16.2701C14.8792 19.199 13.5928 24.7242 13.989 32.691C14.0455 33.8249 14.9813 34.7062 16.1048 34.7062C16.1394 34.7062 16.1752 34.7055 16.2111 34.7034C17.3802 34.6455 18.2816 33.6503 18.2235 32.4812C17.8382 24.7011 19.2645 21.2769 20.3464 19.8133C20.3464 30.7555 20.3464 37.8655 20.3464 48.8399C20.3464 50.0108 21.2956 50.9599 22.4664 50.9599C23.6372 50.9599 24.5865 50.0107 24.5865 48.8399V31.1727H27.4132V48.8399C27.4132 50.0108 28.3624 50.9599 29.5332 50.9599C30.704 50.9599 31.6533 50.0107 31.6533 48.8399C31.6533 37.8608 31.6533 30.781 31.6533 19.8084C32.7769 21.3224 34.1588 24.7729 33.7761 32.4811C33.7182 33.6502 34.6194 34.6453 35.7885 34.7033C35.8244 34.7054 35.8603 34.7061 35.8948 34.7061C37.0183 34.7061 37.9541 33.8248 38.0107 32.6909C38.4069 24.7242 37.1205 19.199 34.1889 16.2701ZM30.9472 7.90903C30.9189 10.6086 28.714 12.7993 26.0003 12.7993C23.2867 12.7993 21.0818 10.6086 21.0535 7.90903H30.9472ZM31.6443 5.55863V6.49557H20.3553V5.55863H20.9296C21.0354 3.91137 21.9421 2.47565 23.2872 1.68983V3.15571C23.2872 3.4882 23.5591 3.76026 23.8917 3.76026C24.209 3.76026 24.481 3.4883 24.481 3.15571V1.19115C24.7832 1.10046 25.0854 1.05502 25.4029 1.04004V3.15571C25.4029 3.4882 25.6748 3.76026 26.0073 3.76026C26.3398 3.76026 26.5967 3.4883 26.5967 3.15571V1.04004C26.9141 1.05512 27.2162 1.10046 27.5185 1.19115V3.15571C27.5185 3.4882 27.7905 3.76026 28.1231 3.76026C28.4555 3.76026 28.7124 3.4883 28.7124 3.15571V1.68983C30.0574 2.49073 30.9641 3.91127 31.07 5.55863H31.6443Z" fill="#CC2500"/>
    </svg>
  )
}

export default Manufacturer
