import React from 'react'
import styles from '../../sass/components/Products/TopSellingProduct.module.scss'
import productItemOne from '../../assets/images/grdnr prdt 3.png'
import { Link } from 'react-router-dom'

const TopSellingProduct2 = () => {
  return (
    <div className={styles.product}>
      <img src={productItemOne} alt="product item" />
      <div className={styles.productDescription}>
        <h3>Gateway</h3>
        <p>The GrandeurSmart Gateway by Mainlogix offers advanced features for seamless connectivity and intelligent control, transforming your home into a smart environment.</p>
        <p>$162.99</p>
        <Link to="/">BUY NOW</Link>
      </div>
    </div>
  )
}

export default TopSellingProduct2
