import React from 'react'
import Hero from '../components/About/Hero'
import Journey from '../components/About/Journey'
import Mission from '../components/About/Mission'
// import Teams from '../components/About/Teams'
import Footer from '../components/Footer'
import styles from '../sass/pages/About.module.scss'
import Awards from '../components/About/Awards'

const About = () => {
  return (
    <div className={styles.about}>
      <Hero />
      <Journey />
      {/* <Teams /> */}
      <Mission />
      <Awards />
      <Footer />
    </div>
  )
}

export default About
