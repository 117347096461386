import React from 'react'
import styles from '../../sass/components/Home/Achievements.module.scss'

const Achievements = () => {
  return (
    <div className={styles.achievements}>
      <p>We always try to serve our best to you</p>
      <div className={styles.achievementList}>
        <div className={styles.achievement}>
          <span></span>
          <span></span>
        </div>
        <hr />
        <div className={styles.achievement}>
          <span>35+</span>
          <span>Customers</span>
        </div>
        <hr />
        <div className={styles.achievement}>
          <span>3</span>
          <span>Countries Reached</span>
        </div>
      </div>
    </div>
  )
}

export default Achievements
