import React from 'react'

const Star = () => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 0L16.5309 8.98278H26.3393L18.4042 14.5344L21.4351 23.5172L13.5 17.9656L5.5649 23.5172L8.59584 14.5344L0.660737 8.98278H10.4691L13.5 0Z" fill="#FE9800"/>
    </svg>
  )
}

export default Star
