import React,{useState, useRef} from 'react'
import styles from '../../sass/components/ProductDetail/Hero.module.scss'
import SectionHeader from '../SectionHeader'
import alarmKit from '../../assets/images/productDetail.jpg'
import about from '../../assets/images/about.jpg'
import ArrowDown from '../../assets/icons/ArrowDown'
import ArrowUp from '../../assets/icons/ArrowUp'

const Hero = () => {

  const sliderRef = useRef(null)

  const [quantity, setQuantity] = useState(1)

  const goBack = () => {
    sliderRef.current.scrollTop -= 90
    sliderRef.current.scrollLeft -= 90
  }
  const goForward = () => {
    sliderRef.current.scrollTop += 90
    sliderRef.current.scrollLeft += 90
  }

  return (
    <div className={styles.hero}>
      <div className={styles.images}>
        <img className={styles.fullImage} src={alarmKit} alt="alarm kit" />
        <div className={styles.slider}>
          <ArrowUp onClick={goBack} />
          <div ref={sliderRef} className={styles.sliderImages}>
            <img className={styles.imagePreview} src={alarmKit} alt="alarm kit" />
            <img className={styles.imagePreview} src={alarmKit} alt="alarm kit" />
            <img className={styles.imagePreview} src={about} alt="alarm kit" />
            <img className={styles.imagePreview} src={about} alt="alarm kit" />
            <img className={styles.imagePreview} src={about} alt="alarm kit" />
            <img className={styles.imagePreview} src={about} alt="alarm kit" />
            <img className={styles.imagePreview} src={about} alt="alarm kit" />
            <img className={styles.imagePreview} src={about} alt="alarm kit" />
          </div>
          <ArrowDown onClick={goForward} />
        </div>
      </div>
      <div className={styles.productDesc}>
        <SectionHeader text="ALARM SECURITY KIT" fontSize="1.5rem" />
        <p className={styles.desc}> 
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore , 
        </p>
        <div className={styles.price}><p>$9.99</p></div>
        <div className={styles.color}>
          <label htmlFor="color">color</label>
          <input type="radio" name="" id="" />
          <input type="radio" name="" id="" />
          <input type="radio" name="" id="" />
        </div>
        <div className={styles.quantity}>
          <span onClick={() => quantity > 1 && setQuantity(quantity - 1)}>{"<"}</span>
          <p>{quantity}</p>
          <span onClick={() => setQuantity(quantity + 1)}>{">"}</span>
        </div>
        <div className={styles.actionButtons}>
          <button>ADD TO CART</button>
          <button>BUY NOW</button>
        </div>
      </div>
    </div>
  )
}

export default Hero
