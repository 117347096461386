import React from 'react'
import styles from '../../sass/components/Products/TopSellingProduct.module.scss'
import productItemOne from '../../assets/images/G_smart_icon_3ii.png'
import { Link } from 'react-router-dom'

const TopSellingProduct = () => {
  return (
    <div className={styles.product}>
      <img src={productItemOne} alt="product item" />
      <div className={styles.productDescription}>
        <h3>Mainlogix Smart Voice Control</h3>
        <p>SMART is the personalized AI voice assistant by Mainlogix, designed to provide seamless control over your home system through simple voice commands.</p>
        <p> . </p>
        <Link to="/">BUY NOW</Link>
      </div>
    </div>
  )
}

export default TopSellingProduct
