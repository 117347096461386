import React from 'react'
import styles from '../../sass/components/Products/ProductList.module.scss'
import ProductItem from './ProductItem'
import ProductItemEnergy from './ProductItemEnergy'
import ProductItemElder from './ProductItemElder'
import ProductItemSecurity from './ProductItemSecurity'

const ProductList = () => {
  return (
    <div className={styles.productList}>
      <form action="" className={styles.search}>
        <input type="search" name="search" id="" placeholder="Search..." />
        <select name="ctegories" id="">
          <option value="">Categories</option>
        </select>
      </form>
      <div className={styles.productItems}>
        <ProductItem />
        <ProductItemEnergy />
        <ProductItemElder />
        <ProductItemSecurity />
      </div>
    </div>
  )
}

export default ProductList
