import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/industrial.svg'

const Service5 = () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Industrial IoT" fontSize="1.5rem" />
        <p>
        The Internet of Things enables industries and enterprises to provide efficient and reliable operations to diverse levels of the industrial value chain by optimizing its processes.Mainlogix IoT solutions introduce efficient robotic systems and devices for smooth production processes.
        </p>
      </div>
    </div>
  )
}

export default Service5
