import React from 'react'
import styles from '../../sass/components/About/Journey.module.scss'
import SectionHeader from '../SectionHeader'
import company from '../../assets/images/company.jpg'
import growth from '../../assets/images/growth.jpg'

const Journey = () => {
  return (
    <div className={styles.journey}>
      <SectionHeader text="Our Journey" fontSize="1.5rem" />
      <div className={styles.journeyContent}>
        <div className={styles.companyDetail}>
          <h3>Company’s profile</h3>
          <p>
            Mainlogix Technology Limited is a pioneering Internet of Things (IoT) company incorporated in Delaware, USA (Registration Number: 7566889), Nova Scotia, Canada (Registration Number: 4526783), and Lagos, Nigeria (Registration Number: 1846913). As a leader in the IoT industry, Mainlogix seamlessly integrates smart home automation and advanced technology across various domains. Our comprehensive solutions span Remote Patient Monitoring, Telemedicine, Mobile Health, Assisted Living, and Home Automation, all designed with a user-centric focus to enhance living standards and well-being. We integrate cutting-edge AI and IoT (AIoT) technologies.
          </p>
        
          <p>
            Mainlogix also offers IoT consulting services, hardware prototyping, IoT analytics, and IoT development. Our expertise extends across fields such as Industrial IoT, Smart Farming, Education, Hospitals, Hotels and Shortlets, Smart Cities, and Medical IoT.

          </p>
          <img src={company} alt="office" />
        </div>
        <div className={styles.historyAndGrowth}>
          <img src={growth} alt="plant in vase" />
          <div>
            <h3>Recent Developments</h3>
            <p>
            In 2024, Mainlogix proudly launched SMART, a cutting-edge AI voice assistant, in strategic partnership with Google and Alexa. SMART revolutionizes smart home management, seamlessly integrating with our mobile app, GrandeurSmart, available on Google and Alexa platforms. This innovative voice assistant empowers users to effortlessly control and command their smart devices, elevating the smart home experience to new heights.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Journey
