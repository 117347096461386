import React from 'react'

const Size = () => {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.66683 50.75H41.0835C41.0835 52.084 42.1662 53.1667 43.5002 53.1667C44.8342 53.1667 45.9168 52.084 45.9168 50.75V45.9167C45.9168 44.5827 44.8342 43.5 43.5002 43.5C42.1662 43.5 41.0835 44.5827 41.0835 45.9167H9.66683C9.66683 44.5827 8.58416 43.5 7.25016 43.5C5.91616 43.5 4.8335 44.5827 4.8335 45.9167V50.75C4.8335 52.084 5.91616 53.1667 7.25016 53.1667C8.58416 53.1667 9.66683 52.084 9.66683 50.75ZM9.66683 25.1672V38.6667C9.66683 40.0007 10.7495 41.0834 12.0835 41.0834H36.2502C37.5842 41.0834 38.6668 40.0007 38.6668 38.6667V25.1672L39.3749 25.8753C40.3174 26.8178 41.8496 26.8178 42.7921 25.8753C43.7346 24.9328 43.7346 23.4006 42.7921 22.4581L25.8754 5.54145C24.9329 4.59653 23.4007 4.59653 22.4582 5.54145L5.54158 22.4581C4.59908 23.4006 4.59908 24.9328 5.54158 25.8753C6.48408 26.8178 8.01625 26.8178 8.95875 25.8753L9.66683 25.1672ZM50.7502 33.8334V12.0834C52.0842 12.0834 53.1668 11.0007 53.1668 9.6667C53.1668 8.3327 52.0842 7.25004 50.7502 7.25004H45.9168C44.5828 7.25004 43.5002 8.3327 43.5002 9.6667C43.5002 11.0007 44.5828 12.0834 45.9168 12.0834V33.8334C44.5828 33.8334 43.5002 34.916 43.5002 36.25C43.5002 37.584 44.5828 38.6667 45.9168 38.6667H50.7502C52.0842 38.6667 53.1668 37.584 53.1668 36.25C53.1668 34.916 52.0842 33.8334 50.7502 33.8334ZM14.5002 20.3339V36.25H33.8335V20.3339L24.1668 10.6672L14.5002 20.3339Z" fill="#CC2500"/>
    </svg>
  )
}

export default Size
