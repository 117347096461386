import React from 'react'
import styles from '../sass/pages/Auth.module.scss'
import logo from '../assets/images/Logo.png'
import { NavLink, Link } from 'react-router-dom' 
import { useLocation } from 'react-router'
import Login from '../components/Auth/Login'
import SignUp from '../components/Auth/SignUp'
import Twitter from '../assets/icons/Twitter'
import LinkedIn from '../assets/icons/LinkedIn'
import Instagram from '../assets/icons/Instagram'

const Auth = () => {

  const location = useLocation()

  const setStyles = (link) => {
    return {
      color: link.isActive ? "white" : "black",
      backgroundColor: link.isActive ? "#cc2500" : "",
      padding: link.isActive ? "5px 10px" : "",
      borderRadius: link.isActive ? "500px" : ""
    }
  }

  return (
    <div className={styles.auth}>
      <div className={styles.colOne}>
        <header className={styles.authHeader}>
          <Link to="/"> <img src={logo} alt="mainlogix logo" /> </Link>
        </header>
      </div>
      <div className={styles.colTwo}>
        <div className={styles.authContent}>
          <nav className={styles.nav}>
            <NavLink to="/signup" style={setStyles}>Sign Up</NavLink>
            <NavLink to="/login" style={setStyles}>Log In</NavLink>
          </nav>
          {location.pathname === '/signup' ? <SignUp /> : <Login />}
          <div className={styles.social}>
            <p>social networks</p>
            <hr />
            <div className={styles.socialIcons}>
              <Link to="/"> <Twitter /> </Link>
              <Link to="/"> <Instagram /> </Link>
              <Link to="/"> <LinkedIn /> </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
