import React from 'react'
import styles from '../../sass/components/Home/Hero.module.scss'
import Achievements from './Achievements'
import Carousel from '../Carousel'
import Header from '../Header'
import HeroCarouselContent from './HeroCarouselContent'
import HeroCarouselMedical from './HeroCarouselMedical'
import HeroCarouselGrandeur from './HeroCarouselGrandeur'
import medical from '../../assets/images/medical.jpg'
import medicineSlide from '../../assets/images/medicineSlider.jpg'
import hubTechModule from '../../assets/images/hubTechModule.png'
import homeSlide from '../../assets/images/homeSlider.jpg'



const Hero = () => {
  const slideStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'grey',
    backgroundBlendMode: 'multiply',
  }
  const slides = [
  //   {
  //   imgSrc: hubTechModule,
  //   content: <HeroCarouselContent />,
  //   slideStyle
  // },
   {
    imgSrc: medical,
    content: <HeroCarouselMedical />,
    slideStyle
  }, {
    imgSrc: homeSlide,
    content: <HeroCarouselGrandeur />,
    slideStyle
  }]

  return (
    <div className={styles.hero}>
      <Header />
      <div className={styles.heroCarousel}>
        <Carousel carouselData={slides} controls={{type: "in"}} />
      </div>
      {/* <Achievements /> */}
    </div>
  )
}

export default Hero

