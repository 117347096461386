import React from 'react'

const LeftArrow = ({color, moveLeft, className}) => {
  return (
    <svg onClick={moveLeft} className={className} width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0174 3.33855L7.07019 16.3278L24.3647 27.8211L22.3368 31.2503L0.325589 16.6224L21.8948 0.0906742L24.0174 3.33855Z" fill={color}/>
    </svg>
  )
}

export default LeftArrow
