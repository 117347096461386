import React from 'react'
import styles from '../../sass/components/ProductDetail/MoreDetail.module.scss'
import SectionHeader from '../SectionHeader'
import alarmKitDoorbell from '../../assets/images/alarmKitDoorbell.jpg'

const MoreDetail = () => {
  return (
    <div className={styles.moreDetail}>
      <SectionHeader text="Alarm Security Kit + Video Doorbell Wired." fontSize="1.5rem" />
      <h5>You have the opportunity to watch. A smart house has excellent video quality.</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
      </p>
      <img src={alarmKitDoorbell} alt="alarm kit plus doorbell" />
    </div>
  )
}

export default MoreDetail
