import React, {useContext} from 'react'
import { Link, NavLink } from 'react-router-dom' 
import styles from '../sass/components/Header.module.scss'
import Logo from '../assets/images/Logo.png'
import { mobileNavContext } from '../App'

const Header = ({showNav, externalStyles, linkStyles}) => {

  const mobileNav = useContext(mobileNavContext)

  const {showMobileNav, setShowMobileNav} = mobileNav

  const setStyles = (link) => {
    return {
      ...linkStyles,
      color: link.isActive ? "white" : linkStyles?.color,
      backgroundColor: link.isActive ? "#cc2500" : linkStyles?.backgroundColor,
      padding: link.isActive ? "5px 10px" : linkStyles?.padding,
      borderRadius: link.isActive ? "500px" : linkStyles?.borderRadius
    }
  }

  return (
    <header className={styles.header} style={{...externalStyles}} >
      <div className={styles.logo}><Link to="/"> <img src={Logo} alt="logo" /> </Link></div>
      <nav className={styles.nav}>
        <NavLink to="/" style={setStyles} >Home</NavLink>
        <NavLink to="/products" style={setStyles} >Products</NavLink>
        <NavLink to="/blog" style={setStyles} >Blog</NavLink>
        <NavLink to="/about" style={setStyles} >About Us</NavLink>
        <NavLink to="/contact" style={setStyles} >Contact Us</NavLink>
      </nav>
      <div onClick={() => setShowMobileNav(!showMobileNav)} className={styles.hamburgerMenu}>
        <div style={{backgroundColor: linkStyles?.color}} className={styles.dash}></div>
        <div style={{backgroundColor: linkStyles?.color}} className={styles.dash}></div>
        <div style={{backgroundColor: linkStyles?.color}} className={styles.dash}></div>
      </div>
    </header>
  )
}

export default Header
