import React from 'react'
import {Link} from 'react-router-dom'
import styles from '../../sass/components/Home/HeroCarouselContent.module.scss'
const HeroCarouselMedical = () => {
  return (
    <div className={styles.content}>
      <h2>Mainlogix Health and Elderly care IOT.</h2>
      <p>
        With our smart Health and Elderly care kit, we provide remote monitoring, prevention of disease, Diet Balancing, Medical Habits like taking of drugs at right time, Walking speed and Temperature measurement for Elderly ones and patients
      </p>
      <Link to="/products">GO TO SHOP</Link>
    </div>
  )
}

export default HeroCarouselMedical
