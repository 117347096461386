import React from 'react'
import SectionHeader from '../SectionHeader'
import styles from '../../sass/components/Products/TopSelling.module.scss'
import TopSellingProduct from './TopSellingProduct'
import TopSellingProduct2 from './TopSellingProduct2'
import TopSellingProduct3 from './TopSellingProduct3'
import TopSellingProduct4 from './TopSellingProduct4'
import TopSellingProduct5 from './TopSellingProduct5'

const TopSelling = () => {
  return (
    <div className={styles.topSelling}>
      <SectionHeader text="Top Selling" />
      <div className={styles.topSellingProducts}>
        <TopSellingProduct />
        <TopSellingProduct2 />
        <TopSellingProduct3 />
        <TopSellingProduct4 />
        <TopSellingProduct5 />
      </div>
    </div>
  )
}

export default TopSelling
