import React from 'react'
import styles from '../../sass/components/ProductDetail/Specification.module.scss'
import SectionHeader from '../SectionHeader'
import Colour from '../../assets/icons/Colour'
import Size from '../../assets/icons/Size'
import Manufacturer from '../../assets/icons/Manufacturer'
import Material from '../../assets/icons/Material'

const Specification = () => {
  return (
    <div className={styles.specification}>
      <SectionHeader text="Specifications" fontSize="1.5rem" />
      <div className={styles.specificationList}>
        <div className={styles.specificationItem}>
          <Size />
          <span>size</span>
          <p>Lorem Ipsum</p>      
        </div>
        <div className={styles.specificationItem}>
          <Colour />
          <span>colour</span>
          <p>Lorem Ipsum</p>
        </div>
        <div className={styles.specificationItem}>
          <Material />
          <span>material</span>
          <p>Lorem Ipsum</p>
        </div>
        <div className={styles.specificationItem}>
          <Manufacturer />
          <span>manufacturer</span>
          <p>Lorem Ipsum</p>
        </div>
      </div>
      
    </div>
  )
}

export default Specification
