import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Products/CarouselContent.module.scss'


const CarouselContenttAgric = () => {
  return (
    <div className={styles.content}>
      <h2>Smart Energy Management Kit</h2>
      <p> Tools for efficient energy usage and monitoring, 
        including smart thermostats, energy-efficient lighting, 
        and real-time energy consumption tracking, helping reduce 
        utility bills and environmental impact.
      </p>
      <Link to="/">BUY NOW</Link>
      
    </div>
  )
}

export default CarouselContenttAgric
