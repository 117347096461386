import React from 'react'
import styles from '../../sass/components/Products/TopSellingProduct.module.scss'
import productItemOne from '../../assets/images/Mainlogix Product Alarm2.png'
import { Link } from 'react-router-dom'

const TopSellingProduct5 = () => {
  return (
    <div className={styles.product}>
      <img src={productItemOne} alt="product item" />
      <div className={styles.productDescription}>
        <h3>SOS</h3>
        <p>The Smart FOB Button is a compact, portable device designed for quick and easy access to a variety of smart home functions.</p>
        <p>$29.99</p>
        <Link to="/">BUY NOW</Link>
      </div>
    </div>
  )
}

export default TopSellingProduct5
