import React from 'react'

const RightArrow = ({color, moveRight, className}) => {
  return (
    <svg onClick={moveRight} className={className} width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0467555 27.8297L17.354 15.6179L0.0623294 3.33957L2.16135 0.00403144L24.1689 15.631L2.14153 31.1733L0.0467555 27.8297Z" fill={color}/>
    </svg>
  )
}

export default RightArrow
