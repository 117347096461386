import React from 'react'
import styles from '../../sass/pages/Auth.module.scss'
import { Link } from 'react-router-dom'

const Register = () => {
  return (
    <form className={styles.signup}>
      <input type="text" name="fullName" placeholder="Full Name" />
      <input type="email" name="email" placeholder="Email" />
      <input type="password" name="password" placeholder="Password" />
      <input type="password" name="confirmPassword" placeholder="Confirm Password" />
      <div className={styles.termsAndConditions}>
        <input type="checkbox" name="termsAndConditions" id="" />
        <label htmlFor="termsAndConditions">
          I agree to the <Link to="/">terms of service</Link>
        </label>
      </div>
      <button type="submit">Sign Up</button>
      <div className={styles.otherLinks}>
        <Link to="/login">Log In?</Link>
      </div>
    </form>
  )
}

export default Register
