import React from 'react'

const Colour = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.9998 4.16663C13.4894 4.16663 4.1665 13.4895 4.1665 25C4.1665 36.5 13.4894 45.8333 24.9998 45.8333C36.5103 45.8333 45.8332 36.5 45.8332 25C45.8332 13.4895 36.5103 4.16663 24.9998 4.16663ZM16.6665 36.4583C13.7915 36.4583 11.4582 34.125 11.4582 31.25C11.4582 28.375 13.7915 26.0416 16.6665 26.0416C19.5415 26.0416 21.8748 28.375 21.8748 31.25C21.8748 34.125 19.5415 36.4583 16.6665 36.4583ZM19.7915 16.6666C19.7915 13.7916 22.1248 11.4583 24.9998 11.4583C27.8748 11.4583 30.2082 13.7916 30.2082 16.6666C30.2082 19.5416 27.8748 21.875 24.9998 21.875C22.1248 21.875 19.7915 19.5416 19.7915 16.6666ZM33.3332 36.4583C30.4582 36.4583 28.1248 34.125 28.1248 31.25C28.1248 28.375 30.4582 26.0416 33.3332 26.0416C36.2082 26.0416 38.5415 28.375 38.5415 31.25C38.5415 34.125 36.2082 36.4583 33.3332 36.4583Z" fill="#CC2500"/>
    </svg>

  )
}

export default Colour
