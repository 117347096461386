import React,{useContext} from 'react'
import styles from '../sass/components/MobileNav.module.scss'
import { NavLink } from 'react-router-dom'
import { mobileNavContext } from '../App'
import Close from '../assets/icons/Close'

const MobileNav = () => {

  const mobileNav = useContext(mobileNavContext)

  const {showMobileNav, setShowMobileNav} = mobileNav

  const setStyles = (link) => {
    return {
      color: link.isActive ? "white" : "black",
      backgroundColor: link.isActive && "red",
      padding: link.isActive && "5px 10px",
      borderRadius: link.isActive && "5px"
    }
  }

  const closeNav = () => {
    setShowMobileNav(!showMobileNav)
  }

  return (
    <div className={styles.mobileNav}>
        <div className={styles.close} onClick={closeNav}>
          <Close />
        </div>
        <NavLink onClick={closeNav} to="/" style={setStyles} >Home</NavLink>
        <NavLink onClick={closeNav} to="/products" style={setStyles} >Products</NavLink>
        <NavLink onClick={closeNav} to="/blog" style={setStyles} >Blog</NavLink>
        <NavLink onClick={closeNav} to="/about" style={setStyles} >About Us</NavLink>
        <NavLink onClick={closeNav} to="/contact" style={setStyles} >Contact Us</NavLink>
    </div>
  )
}

export default MobileNav
