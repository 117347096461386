import React from 'react'
import styles from '../../sass/pages/Auth.module.scss'
import { Link } from 'react-router-dom'

const Login = () => {
  return (
    <form className={styles.login}>
      <input type="email" name="email" placeholder="Email" />
      <input type="password" name="password" placeholder="Password" />
      <button type="submit">Log In</button>
      <div className={styles.otherLinks}>
        <Link to="/signup">Sign Up?</Link>
        <Link to="/">Forgot Password?</Link>
      </div>
    </form>
  )
}

export default Login
