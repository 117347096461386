import React from 'react'
import styles from '../../sass/components/Products/ProductItem.module.scss'
import productItemOne from '../../assets/images/Grandeur_smart_Smart_security_safety.png'
import { Link } from 'react-router-dom'
const ProductItemSecurity = () => {
  return (
    <div className={styles.productItem}>
      <div className={styles.productDescription}>
        <h3>Smart Security and Safety Kit</h3>
        <p>Advanced security systems to protect 
                          life and property, such as smart locks, surveillance cameras, 
                          motion sensors and more, providing peace of mind to homeowners.</p>
        <Link to="/">BUY NOW</Link>
      </div>
      <img src={productItemOne} alt="product item" />
    </div>
  )
}

export default ProductItemSecurity
