import React from 'react';

const Instagram = () => {
  return (
    <svg width="25" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill="black" />
      <path d="M17.9999 13.3137C15.4195 13.3137 13.3136 15.4195 13.3136 18C13.3136 20.5805 15.4195 22.6863 17.9999 22.6863C20.5804 22.6863 22.6863 20.5805 22.6863 18C22.6863 15.4195 20.5804 13.3137 17.9999 13.3137ZM32.0554 18C32.0554 16.0594 32.073 14.1363 31.964 12.1992C31.855 9.94924 31.3417 7.95236 29.6964 6.30705C28.0476 4.65822 26.0542 4.14846 23.8042 4.03947C21.8636 3.93049 19.9405 3.94807 18.0034 3.94807C16.0628 3.94807 14.1398 3.93049 12.2027 4.03947C9.95266 4.14846 7.95578 4.66174 6.31047 6.30705C4.66164 7.95588 4.15188 9.94924 4.04289 12.1992C3.93391 14.1399 3.95149 16.0629 3.95149 18C3.95149 19.9371 3.93391 21.8637 4.04289 23.8008C4.15188 26.0508 4.66516 28.0477 6.31047 29.693C7.9593 31.3418 9.95266 31.8516 12.2027 31.9606C14.1433 32.0695 16.0663 32.052 18.0034 32.052C19.9441 32.052 21.8671 32.0695 23.8042 31.9606C26.0542 31.8516 28.0511 31.3383 29.6964 29.693C31.3452 28.0442 31.855 26.0508 31.964 23.8008C32.0765 21.8637 32.0554 19.9406 32.0554 18ZM17.9999 25.2106C14.0097 25.2106 10.7894 21.9903 10.7894 18C10.7894 14.0098 14.0097 10.7895 17.9999 10.7895C21.9902 10.7895 25.2105 14.0098 25.2105 18C25.2105 21.9903 21.9902 25.2106 17.9999 25.2106ZM25.5058 12.1781C24.5741 12.1781 23.8218 11.4258 23.8218 10.4942C23.8218 9.56252 24.5741 8.81018 25.5058 8.81018C26.4374 8.81018 27.1898 9.56252 27.1898 10.4942C27.19 10.7154 27.1467 10.9345 27.0621 11.1389C26.9776 11.3434 26.8536 11.5291 26.6972 11.6855C26.5407 11.842 26.355 11.966 26.1505 12.0505C25.9461 12.1351 25.727 12.1784 25.5058 12.1781Z" fill="red" transform="scale(0.7) translate(7.5, 7.5)" />
    </svg>
  );
}

export default Instagram;
