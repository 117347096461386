import React from 'react'
import styles from '../sass/pages/Blog.module.scss'
import Hero from '../components/Blog/Hero'
import Footer from '../components/Footer'
import PopularBlogs from '../components/Blog/PopularBlogs'
import OtherBlogs from '../components/Blog/OtherBlogs'

const Blog = () => {
  return (
    <div className={styles.blog}>
      <Hero />
      <div className={styles.blogs}>
        <PopularBlogs />
        <OtherBlogs />
      </div>
      <Footer />
    </div>
  )
}

export default Blog
