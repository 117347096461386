import React from 'react'
import SectionHeader from '../SectionHeader'
import styles from '../../sass/components/Home/Partners.module.scss'
import lorawan from '../../assets/images/lorawan.png'
import aws from '../../assets/images/aws.png'
import wave from '../../assets/images/wave.png'
import matter from '../../assets/images/matter.png'
import zigbee from '../../assets/images/zigbee2.jpg'
import googleMic from '../../assets/images/GOOgle.png'
import NFC from '../../assets/images/NFC.png'
import Alexa from '../../assets/images/alexa.png'
import smartThings from '../../assets/images/Smartthings.png'
import homeSmart from '../../assets/images/homeKit.jpg'
import philip from '../../assets/images/philiphues.png'

const Partners = () => {
  return (
    <div className={styles.partners}>
      <SectionHeader text="Our Partners" />
      <div className={styles.partnerLogos}>
        <img src={wave} alt="z wave" />
        <img src={aws} alt="aws" />
        <img src={lorawan} alt="lorawan" />
        <img src={matter} alt="matter" />
        <img src={zigbee} alt="zigbee" />
        <img src={Alexa} alt="alexa" height={250}/>
        <img src={googleMic} alt="googleMic" />
        <img src={NFC} alt="NFC" />
        <img src={homeSmart} alt="NFC" />
        <img src={smartThings} alt="NFC" />
        <img src={philip} alt="NFC" />

      </div>
    </div>
  )
}

export default Partners
