import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Home/BlogSnippet.module.scss'
import SectionHeader from '../SectionHeader'
import smart_home from '../../assets/images/smart_home.jpg'
import user from '../../assets/images/user.png'
import Instagram from '../../assets/icons/Instagram'
import Twitter from '../../assets/icons/Twitter'


const BlogSnippet = () => {
  return (
    <div className={styles.blogSnippet}>
      <SectionHeader text="Snippet of Blog" />
      <div className={styles.blogs}>
        <div className={styles.blog}>
          <div className={styles.blogInfo}>
            <img src={smart_home} alt="smart home" />
            <div className={styles.description}>
              <h3>The Internet of Things in Solutions Architecture</h3>
              <p>Information modeling and event management are critical</p>
              <Link to="/">Read More</Link>
            </div>
          </div>
          <hr />
          <div className={styles.authorInfo}>
            <img src={user} alt="blog author" />
            <p><span>By </span><span>Saurabh Shrivastava ·</span></p>
            <p>Nov 17 2022</p>
          </div>
        </div>
        <div className={styles.blog}>
          <div className={styles.blogInfo}>
            <img src={smart_home} alt="smart home" />
            <div className={styles.description}>
              <h3>Cellular Tech Enables Next-Gen Transportation</h3>
              <p>There is no doubt that connected devices and technology</p>
              <Link to="/">Read More</Link>
            </div>
          </div>
          <hr />
          <div className={styles.authorInfo}>
            <img src={user} alt="blog author" />
            <p><span>By </span><span>John Snow</span></p>
            <p>April 22 2022</p>
          </div>
        </div>
        <div className={styles.blog}>
          <div className={styles.blogInfo}>
            <img src={smart_home} alt="smart home" />
            <div className={styles.description}>
              <h3>Unveiling the Potential Relationship between IoT and Cloud Computing</h3>
              <p>Today, if we look around, we find that IoT, the Internet of</p>
              <Link to="/">Read More</Link>
            </div>
          </div>
          <hr />
          <div className={styles.authorInfo}>
            <img src={user} alt="blog author" />
            <p><span>By </span><span>Ritesh Sutaria</span></p>
            <p>April 07 2022</p>
          </div>
        </div>
      </div>
      {/* <div className={styles.newsletter}>
        <div className={styles.brief}>
          <p>Follow us & subscribe our newsletter</p>
          <p>Follow us & stay connected!</p>
        </div>
        <form action="" className={styles.subscribe}>
          <input type="email" placeholder="Enter email..." />
          <button type="submit"> Subscribe </button>
        </form>
        <div className={styles.socials}>
          <Link to="/"><Instagram /></Link>
          <Link to="/"><Twitter /></Link>
        </div>
      </div> */}
    </div>
  )
}

export default BlogSnippet
