import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/iot.85d2281b.svg'

const Service8 = () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Smart Home" fontSize="1.5rem" />
        <p>
        Mainlogix system devices can accommodate user preferences for convenience in their homes. Think of users being able to program their garage door to open as their vehicle approaches the home, or the lights going on as you enter a room. Even the fireplace could turn up the heat as a favorite tune plays over the speakers.All of these point to a wonderful immersion in a world of make-believe. There are a few things cooler than controlling your home from anywhere in the world.
        </p>
      </div>
    </div>
  )
}

export default Service8
