import React from 'react'
import {Link} from 'react-router-dom'
import styles from '../../sass/components/Home/HeroCarouselContent.module.scss'
const HeroCarouselContent = ({content}) => {
  return (
    <div className={styles.content}>
      <h2>{content.title}</h2>
      <p>{content.description}</p>
      <Link to={content.link}>{content.linkText}</Link>
    </div>
  )
}

export default HeroCarouselContent
