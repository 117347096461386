import React from 'react'
import { Link } from 'react-router-dom'
import SectionHeader from '../components/SectionHeader'
import styles from '../sass/pages/FAQ.module.scss'
import Accordion from '../components/Accordion'
import Projects from '../components/FAQ.jsx/Projects'
import Header from '../components/Header'
import faqIllustration from '../assets/images/faqIllustration.svg'
import Footer from '../components/Footer'

const FAQ = () => {

  const faqs = [
    {
      question: "FAQ 1",
      answer: "Enter your answer here. Be thoughtful, write clearly and concisely. Go over what you’ve written to make sure that if it was the first time you were visiting site,  you’d understand your answer."
    },
    {
      question: "FAQ 2",
      answer: "Enter your answer here. Be thoughtful, write clearly and concisely. Go over what you’ve written to make sure that if it was the first time you were visiting site,  you’d understand your answer."
    },
    {
      question: "FAQ 3",
      answer: "Enter your answer here. Be thoughtful, write clearly and concisely. Go over what you’ve written to make sure that if it was the first time you were visiting site,  you’d understand your answer."
    },
    {
      question: "FAQ 4",
      answer: "Enter your answer here. Be thoughtful, write clearly and concisely. Go over what you’ve written to make sure that if it was the first time you were visiting site,  you’d understand your answer."
    },
  ]

  return (
    <div className={styles.faq}>
      <Header />
      <div className={styles.hero}>
        <div className={styles.heroDesc}>
          <div className={styles.plusIcon}>
            +
          </div>
          <SectionHeader text="Frequently asked questions" fontSize="1.5rem" />
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
          </p>
          <Link to="/contact">ASK A QUESTION</Link>
        </div>
        <div className={styles.heroIllustration}>
          <img src={faqIllustration} alt="question mark" />
        </div>
      </div>
      <div className={styles.faqList}>
        <h3>General Questions</h3>
        <Accordion faqs={faqs} />
      </div>
      <Projects />
      <Footer />
    </div>
  )
}

export default FAQ
