import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../sass/components/Blog/OtherBlogs.module.scss'
import avatar from '../../assets/images/avatar.png'
import growth from '../../assets/images/growth.jpg'
import Facebook from '../../assets/icons/Facebook'
import Twitter from '../../assets/icons/Twitter'
import LinkedIn from '../../assets/icons/LinkedIn'
import Audi from '../../assets/images/audi.jpg';
import IOT from '../../assets/images/IOT.jpg';
import Cellular from '../../assets/images/cellular.jpg'

const OtherBlogs = () => {
  return (
    <div className={styles.otherBlogs}>
      <div className={styles.blogs}>
        <div className={styles.blog}>
          <h4>The Internet of Things in Solutions Architecture</h4>
          <p>
          Information modeling and event management are critical for detecting changes across complex industrial systems. Industrial IoT solutions can address this
          </p>
          <img className={styles.blogImage} src={growth} alt="growth" />
          <div className={styles.authorInfo}>
            <img src={avatar} alt="avatar" />
            <p>Saurabh Shrivastava</p>
          </div>
          <hr />
          <div className={styles.socials_date}>
            <div className={styles.socials}>
              <Link to="/"><Facebook /></Link>
              <Link to="/"><Twitter /></Link>
              <Link to="/"><LinkedIn /></Link>
            </div>
            <span className={styles.date}>Apr. 09, 22</span>  
          </div>  
        </div>
        <div className={styles.blog}>
          <h4>Cellular Tech Enables Next-Gen Transportation</h4>
          <p>
          There is no doubt that connected devices and technologies are transforming how humans and machines work together in places like operating rooms, factories, and roadways/intersection
          </p>
          <img className={styles.blogImage} src={Cellular} alt="Cellular" />
          <div className={styles.authorInfo}>
            <img src={avatar} alt="avatar" />
            <p>Graham Hope</p>
          </div>
          <hr />
          <div className={styles.socials_date}>
            <div className={styles.socials}>
              <Link to="/"><Facebook /></Link>
              <Link to="/"><Twitter /></Link>
              <Link to="/"><LinkedIn /></Link>
            </div>
            <span className={styles.date}>Apr 20, 2022</span>  
          </div>  
        </div>
        <div className={styles.blog}>
          <h4>Audi’s Urbansphere: The Autonomous Car Cabin of the Future</h4>
          <p>
          Audi has unveiled its latest vision of what Level 4 autonomous transport might look like – and it’s probably not what you might expect.
          </p>
          <img className={styles.blogImage} src={Audi} alt="Audi" />
          <div className={styles.authorInfo}>
            <img src={avatar} alt="avatar" />
            <p>Connected World</p>
          </div>
          <hr />
          <div className={styles.socials_date}>
            <div className={styles.socials}>
              <Link to="/"><Facebook /></Link>
              <Link to="/"><Twitter /></Link>
              <Link to="/"><LinkedIn /></Link>
            </div>
            <span className={styles.date}>20th April 2022</span>  
          </div>  
        </div>
        <div className={styles.blog}>
          <h4>Unveiling the Potential Relationship between IoT and Cloud Computing</h4>
          <p>
            Today, if we look around, we find that IoT, the Internet of Things, disrupts our daily lives, either at home or the workplace. It has been 20 years since this concept has knocked the tech world.
          </p>
          <img className={styles.blogImage} src={IOT} alt="IOT" />
          <div className={styles.authorInfo}>
            <img src={avatar} alt="avatar" />
            <p>Graham Hope</p>
          </div>
          <hr />
          <div className={styles.socials_date}>
            <div className={styles.socials}>
              <Link to="/"><Facebook /></Link>
              <Link to="/"><Twitter /></Link>
              <Link to="/"><LinkedIn /></Link>
            </div>
            <span className={styles.date}>12 June 2021</span>  
          </div>  
        </div>  
      </div>      
      <div className={styles.pagination}>
        <p>1</p>
        <p>2</p>
        <p>3</p>
        <p>{">"}</p>
      </div>
    </div>
  )
}

export default OtherBlogs
