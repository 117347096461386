import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/finance.svg'

const Service3= () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Smart Cities / Communities" fontSize="1.5rem" />
        <p>
        Smart Cities or Communities enhance governance at the grassroots level of society by helping to bring public administration to the control of the people themselves. And with MainLogix’ Smart Cities devices forming a better part of public infrastructure, people can expect optimized management of public resources in a smartly organized city/community.
        </p>
      </div>
    </div>
  )
}

export default Service3