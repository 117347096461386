import React from 'react'
import styles from '../../sass/components/Products/ProductItem.module.scss'
import productItemOne from '../../assets/images/Grandeur_smart_Light_n_Energy_2.png'
import { Link } from 'react-router-dom'
const ProductItemEnergy = () => {
  return (
    <div className={styles.productItem}>
      <div className={styles.productDescription}>
        <h3>Smart Energy Management Kit</h3>
        <p>
          Tools for efficient energy usage and monitoring, 
          including smart thermostats, energy-efficient lighting, 
          and real-time energy consumption tracking, helping reduce 
          utility bills and environmental impact.
        </p>
        <Link to="/">BUY NOW</Link>
      </div>
      <img src={productItemOne} alt="product item" />
    </div>
  )
}

export default ProductItemEnergy
