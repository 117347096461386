import React, { useEffect, useState, useCallback } from 'react'
import styles from '../sass/components/Carousel.module.scss'
import LeftArrow from '../assets/icons/LeftArrow'
import RightArrow from '../assets/icons/RightArrow'

const Carousel = ({carouselData, controls, slidesStyle, fixedContent}) => {
  // let interval

  const defaultSlideStyles = { 
    backgroundRepeat: "no-repeat", 
    backgroundSize: "cover", 
    backgroundPosition: "center center", 
  }

  const controlType = controls.type === "in" ? "innerControl" : "outerControl"

  const TOTAL_SLIDES = carouselData.length
  const initialState = carouselData.map((data,index) => "base")
  const [animationDirection, setAnimationDirection] = useState(initialState)
  const [activeSlide, setActiveSlide] = useState(0)

  const moveLeft = () => {
    const newState = [...animationDirection]
    const next = activeSlide - 1 < 0 ? TOTAL_SLIDES - 1 : activeSlide - 1
    newState[activeSlide] = "c_r"
    newState[next] = "l_c"
    setAnimationDirection(newState)
    setActiveSlide(next)
  }

  const moveRight = useCallback(
    () => {
      const newState = [...animationDirection]
      const next = activeSlide + 1 === TOTAL_SLIDES ? 0 : activeSlide + 1
      newState[activeSlide] = "c_l"
      newState[next] = "r_c"
      setAnimationDirection(newState)
      setActiveSlide(next)
    },
    [TOTAL_SLIDES, activeSlide, animationDirection],
  )


  const slides = carouselData.map((data, index) =>(
    
    <div key={index} className={`${styles.slide} ${styles[animationDirection[index]]}`} style={{ backgroundImage: `url('${data?.imgSrc}')`, ...defaultSlideStyles, ...data?.slideStyle}}>
          {data?.content}
      </div>
    ))

  useEffect(() => {
    const interval = setInterval(moveRight, 7000);
		return () => clearInterval(interval);
  }, [moveRight])

  
  return (
    <div className={`${styles.carousel} ${styles[controlType]}`}>
      <LeftArrow className={styles.leftArrow} moveLeft={moveLeft} />
      <div style={{...slidesStyle}} className={styles.slides} >
        {fixedContent}
        {slides}
      </div>
      <RightArrow className={styles.rightArrow} moveRight={moveRight} />
    </div>
  )
}

export default Carousel


/*
CAROUSEL DATA STRUCTURE

carouselData = [
  {
    imgSrc: string,
    content: jsx,
    slideStyle: object,
    slidesStyle: object,

  }
]

------------------------------
ANIMATION DIRECTION

L - C === LEFT TO CENTER
C - L === CENTER TO LEFT
C - R === CENTER TO RIGHT
R - C === RIGHT TO CENTER

------------------------------
CONTROL

controls is either "in" or "out"

*/