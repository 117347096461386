import React from 'react'
import Hero from '../components/Services/Hero'
import Service from '../components/Services/Service'
import TopServices from '../components/Services/TopServices'
import styles from '../sass/pages/Services.module.scss'
import Footer from '../components/Footer'
import Service2 from '../components/Services/Service2'
import Service3 from '../components/Services/Service3'
import Service4 from '../components/Services/Service4'
import Service5 from '../components/Services/Service5'
import Service6 from '../components/Services/Service6'
import Service7 from '../components/Services/Service7'
import Service8 from '../components/Services/Service8'

const Services = () => {
  return (
    <main className={styles.services}>
      <Hero />
      <div className={styles.serviceList}>
        <Service />
        <Service2 />
        <Service3 />
        <Service4 />
        <Service5 />
        <Service6 />
        <Service7 />
        <Service8 />


      </div>
      {/* <iframe className={styles.iframe} width="100%" height="315" src="https://www.youtube.com/embed/dhrX_biPH8c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
      {/* <TopServices /> */}
      <Footer />
    </main>
  )
}

export default Services
