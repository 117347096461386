import React from 'react'
import styles from '../../sass/components/About/Hero.module.scss'
import Header from '../Header'



const Hero = () => {

  const headerStyles = {
    width: "100%",
  }

  return (
    <div className={styles.hero}>
      <Header linkStyles={{color: "white"}} externalStyles={headerStyles}/>
      <h2>About Us</h2>
    </div>
  )
}

export default Hero
