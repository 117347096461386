import React from 'react'

const Mail = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9998 25.3333C15.9991 23.7586 16.397 22.2094 17.1566 20.83C17.9162 19.4506 19.0126 18.286 20.3438 17.4447C21.6749 16.6035 23.1973 16.1129 24.7692 16.0188C26.3411 15.9247 27.9112 16.2302 29.3332 16.9066V5.33331H2.6665V26.6666H16.1065C16.0398 26.2266 15.9998 25.7866 15.9998 25.3333ZM5.33317 7.99998L15.9998 14.6666L26.6665 7.99998V10.6666L15.9998 17.3333L5.33317 10.6666V7.99998ZM23.1198 29.3333L18.3998 24.6133L20.2798 22.7333L23.1065 25.56L28.7598 19.9066L30.6665 21.7866L23.1198 29.3333Z" fill="#CC2500"/>
    </svg>
  )
}

export default Mail
