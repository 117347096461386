import React from 'react'
import styles from '../../sass/components/Products/TopSellingProduct.module.scss'
import productItemOne from '../../assets/images/Mainlogix Product cam5.png'
import { Link } from 'react-router-dom'

const TopSellingProduct3 = () => {
  return (
    <div className={styles.product}>
      <img src={productItemOne} alt="product item" />
      <div className={styles.productDescription}>
        <h3>Intelligent Camera</h3>
        <p>Advanced Surveillance for Enhanced Security</p>
        <p>$39.99</p>
        <Link to="/">BUY NOW</Link>
      </div>
    </div>
  )
}

export default TopSellingProduct3
