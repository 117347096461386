import React from 'react'
import styles from '../../sass/components/Products/Hero.module.scss'
import Header from '../Header'
import CarouselContent from './CarouselContent'
import Carousel from '../Carousel'
import agricSlide from '../../assets/images/agricSlider.jpg'
import medicineSlide from '../../assets/images/medicineSlider.jpg'
import homeSlide from '../../assets/images/homeSlider.jpg'
import CarouselContentEderly from './CarouselContentEderly'
import CarouselContenttAgric from './CarouselContenttAgric'


const Hero = () => {

  const headerStyles = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: "100%",
    zIndex: '1',
  }
  const slideStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'grey',
    backgroundBlendMode: 'multiply',
  }

  const slides = [{
    imgSrc: agricSlide,
    content: <CarouselContenttAgric />,
    slideStyle
  }, {
    imgSrc: medicineSlide,
    content: <CarouselContentEderly />,
    slideStyle
  }, {
    imgSrc: homeSlide,
    content: <CarouselContent />,
    slideStyle
  }] 

  const header = <Header linkStyles={{color: "white"}} externalStyles={headerStyles}/>
  return (
    <div className={styles.hero}>
      <div className={styles.heroCarousel}>
        <Carousel carouselData={slides} controls={{type: "in"}} fixedContent={header} />
      </div>
    </div>
  )
}

export default Hero
