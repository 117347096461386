import React from 'react'
import Carousel from '../Carousel'
import SectionHeader from '../SectionHeader'
import styles from '../../sass/components/Home/Services.module.scss'
import agricultural from '../../assets/images/agricultural.svg'
import education from '../../assets/images/education.svg'
import finance from '../../assets/images/finance.svg'
import hospitality from '../../assets/images/hospitality.svg'
import industrial from '../../assets/images/industrial.svg'
import medicine from '../../assets/images/medicine.svg'
import transport from '../../assets/images/transport.svg'
import ServiceCarouselContent from './ServiceCarouselContent'



const Services = () => {

  const slidesStyle = {
    borderRadius: "10px",
    border: "10px solid #cc2500",
  }

  const slideStyle = {
    borderRadius: "10px",
    backgroundColor: "white"
  }

  const slides = [{
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: agricultural, 
      industry: "Smart Agricultural",
      description: " IoT use in the agricultural system includes employing IoT devices in agricultural ",
      path: "/services"
    }} />
  }, {
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: industrial, 
      industry: "Industrial",
      description: "Rise of industrial IoT offers industries new ways to improve their productivity",
      path: "/services"
    }} />
  }, {
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: medicine, 
      industry: "Medical",
      description: "IoT in the medical system represents real-time health systems responsible",
      path: "/services"
    }} />
  }, {
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: transport, 
      industry: "Transportation & Logistics",
      description: "IoT devices are set to make transportation and logistic processes smarter",
      path: "/services"
    }} />
  }, {
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: education, 
      industry: "Education",
      description: "IoT in the School/ Educational sector sets to transform the learning environment",
      path: "/services"
    }} />
  }, {
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: finance, 
      industry: "Finance",
      description: "Financial and banking IoT devices champion secured transactions",
      path: "/services"
    }} />
  }, {
    slideStyle,
    content: <ServiceCarouselContent data={{
      illustration: hospitality, 
      industry: "Hospitality",
      description: "With the help of IoT, Hotels, and Hospitality management industries can create",
      path: "/services"
    }} />
  }]

  return (
    <section className={styles.services}>
      <div className={styles.description}>
        <SectionHeader text="Our Services" />
        <p>
          Mainlogix has different subsidiaries like GrandeurSmart: that focus on smart city and smart home. Intelkard is also a subsidiary of Mainlogix that focuses on using NFC technology to solve various challenges in IOT.
        </p>
        <br />
        <p>
          Mainlogix Technology focuses also on IOT consulting services, IOT hardware Prototype, IOT analytics and IOT development. We focus also in various fields like Industrial IOT , Smart Farm Education, Smart city and Medical IOT.
        </p>
      </div>
      <div className={styles.carousel_container}>
        <Carousel carouselData={slides} slidesStyle={slidesStyle} controls={{type: "out"}} />
      </div>
    </section>
  )
}

export default Services
