import React from 'react'
import styles from '../sass/pages/ProductDetail.module.scss'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Hero from '../components/ProductDetail/Hero'
import MoreDetail from '../components/ProductDetail/MoreDetail'
import Specification from '../components/ProductDetail/Specification'
import HowTo from '../components/ProductDetail/HowTo'

const ProductDetail = () => {
  return (
    <div className={styles.productDetail}>
      <Header />
      <Hero />
      <MoreDetail />
      <Specification />
      <HowTo />
      <iframe className={styles.iframe} width="100%" height="315" src="https://www.youtube.com/embed/dhrX_biPH8c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      <Footer />
    </div>
  )
}

export default ProductDetail
