import React from 'react'
import styles from '../../sass/components/Home/Hero.module.scss'
import Carousel from '../Carousel'
import Header from '../Header'
import industrialServices from '../../assets/images/industrialServices.svg'
import specializedSusidiaries from '../../assets/images/achievement.jpg'
import healthService from '../../assets/images/medicineSlider.jpg'
import automationService from '../../assets/images/dalle-2NCa3VEEE2.png'
import HeroCarouselContent from './HeroCarouselContent'




const Hero = () => {

  const content = {
    title: 'Smart Home Automation',
    description: 'We transform your home into a secure, efficient, and accessible haven with our smart home solutions. Experience peace of mind with advanced security, optimize energy consumption, and empower independent living for all. Elevate your lifestyle with cutting-edge technology and seamless control.',
    link: '/',
    linkText: 'GO TO SHOP'
  }
  const content2 = {
    title: 'Healthcare Solutions',
    description: 'Revolutionize healthcare delivery with our innovative solutions. Seamlessly manage patient care and monitor health remotely for improved outcomes.',
    link: '/',
    linkText: 'GO TO SHOP'
  }
  const content3 = {
    title: 'Specialized Subsidiaries',
    description: 'GrandeurSmart Focuses on smart home and smart city solutions. Intelkard Utilizes NFC technology for modern business and access cards. Xmedico Specializes in advanced medical devices and healthcare solutions.',
    link: '/',
    linkText: 'GO TO SHOP'
  }
  const slideStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'grey',
    backgroundBlendMode: 'multiply',
  }
  const slides = [{
    imgSrc: automationService,
    content: <HeroCarouselContent content={content} />,
    slideStyle
  }, {
    imgSrc: healthService,
    content: <HeroCarouselContent content={content2} />,
    slideStyle
  }, {
    imgSrc: specializedSusidiaries,
    content: <HeroCarouselContent content={content3} />,
    slideStyle
  }]


  return (
    <div className={styles.hero}>
      <Header />
      <div className={styles.heroCarousel}>
        <Carousel carouselData={slides} controls={{type: "in"}} />
      </div>
    </div>
  )
}

export default Hero

