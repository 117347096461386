import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/agricultural.svg'

const Service = () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Smart Agriculture" fontSize="1.5rem" />
        <p>
        With Mainlogix Smart Agriculture solution, farm devices and implements will become easier to use and operate. The capability to measure real-time data enables farmers to maximize equipment performance, optimize labour, and increase the quantity and quality of products. It will change the face of agriculture from the hard labour narrative to intelligent and automated farming.
        </p>
      </div>
    </div>
  )
}

export default Service
