import React from 'react'
import { useRef } from 'react'
import styles from '../sass/components/Accordion.module.scss'

const Pane = ({info}) => {

  const answerRef = useRef(null)
  let height;

  const toggleFaq = () => {

    height = answerRef.current.scrollHeight * 1.5
    const newState = [...info.height]
    newState[info.index] = info.height[info.index] === "0" ? height : "0"


    if (info.current !== "") {
      newState[info.current] = "0"
      info.setHeight(newState)
      info.index === info.current ? info.setCurrent("") : info.setCurrent(info.index)
    } else {
      info.setHeight(newState)
      info.setCurrent(info.index)
    }

    
  }

  return (
    <div className={styles.pane}>
      <div onClick={toggleFaq} className={styles.question}>{info.faq.question}</div>
      <div ref={answerRef} style={{height: `${info.height[info.index]}px`, padding: info.height[info.index] === "0" ? "0 30px" : "20px 30px" }} className={styles.answer}>{info.faq.answer}</div>
    </div>
  )
}

export default Pane
