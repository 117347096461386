import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/hospitality.svg'

const Service4 = () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Medical IoT" fontSize="1.5rem" />
        <p>
        In the health care sector, Mainlogix systems bring automation to solving myriads of problems medical professionals face when diagnosing diseases or conditions. These smart systems will enable devices to be more proactive by enabling enhanced connectivity to the bodies of patients in health diagnosis.
        </p>
      </div>
    </div>
  )
}

export default Service4
