import React from 'react'
import styles from '../sass/components/SectionHeader.module.scss'

const SectionHeader = ({text, fontSize}) => {
  return (
    <header className={styles.header}>
      <h2 style={{fontSize}}>{text}</h2>
      <hr />
    </header>
  )
}

export default SectionHeader
