import React from 'react'
import Hero from '../components/Products/Hero'
import ProductList from '../components/Products/ProductList'
import TopSelling from '../components/Products/TopSelling'
import styles from '../sass/pages/Products.module.scss'
import Footer from '../components/Footer'

const Products = () => {
  return (
    <main className={styles.products}>
      <Hero />
      <ProductList />
      <TopSelling />
      <Footer />
    </main>
  )
}

export default Products
