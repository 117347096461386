import "./App.scss";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Services from "./pages/Services";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import MobileNav from "./components/MobileNav";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Auth from "./pages/Auth";
import JoinUs from "./pages/JoinUs";
import ProductDetail from "./pages/ProductDetail";
import Feedback from "./pages/Feedback";

export const mobileNavContext = React.createContext();

function App() {
	const [showMobileNav, setShowMobileNav] = useState(false);

	return (
		<mobileNavContext.Provider value={{ showMobileNav, setShowMobileNav }}>
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/join" element={<JoinUs />} />
						<Route path="/about" element={<About />} />
						<Route path="/login" element={<Auth />} />
						<Route path="/signup" element={<Auth />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/products" element={<Products />} />
						<Route path="/products/:id" element={<ProductDetail />} />
						<Route path="/services" element={<Services />} />
						<Route path="/feedback" element={<Feedback />} />
					</Routes>
					{showMobileNav && <MobileNav />}
				</BrowserRouter>
			</div>
		</mobileNavContext.Provider>
	);
}

export default App;
