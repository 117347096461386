import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../sass/components/Footer.module.scss'
import logo from '../assets/images/Logo.png'
import appstore from '../assets/images/Apple_white_bg.png'
import playstore from '../assets/images/google_white_bg.png'
import Facebook from '../assets/icons/Facebook'
import LinkedIn from '../assets/icons/LinkedIn'
import Instagram from '../assets/icons/Instagram'
import Twiiter from '../assets/icons/Twitter'
import {TwitterTimelineEmbed} from 'react-twitter-embed'
import Twitter from '../assets/icons/Twitter'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <hr />
      <div className={styles.otherLinks}>
        <div className={styles.resources}>
          <h3>RESOURCES</h3>
          <Link to="/">Sign In</Link>
          <Link to="/">Sign Up</Link>
          <Link to="/">FAQs</Link>
          <div className={styles.socials}>
            <a href='https://m.facebook.com/p/Mainlogix-Technology-Limited-100067015779535/'>
              <Facebook />
            </a>
            <a href="https://www.instagram.com/mainlogixtechafrica/">
              <Instagram />
            </a>
            <a href='https://twitter.com/MainLogiXTech'>
              <Twitter />
            </a>
            <a href='https://ng.linkedin.com/company/mainlogix'>
              <LinkedIn />
            </a>
          </div>
          <div className={styles.appLinks}>
            <Link to="/">
              <img 
                src={appstore} 
                alt="iOS appstore"
                className={styles.mobileApp} />
            </Link>
            <a href="https://play.google.com/store/apps/details?id=com.grandeursmart.home.android">
              <img 
                src={playstore} 
                alt="google playstore"
                className={styles.mobileApp}
              />
            </a>
          </div>
          <Link to="/">2024 mainlogix.io</Link>
        </div>
        <div className={styles.company}>
          <h3>COMPANY</h3>
          <Link to="/">Home</Link>
          <Link to="/products">Products</Link>
          <Link to="/about">About</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/services">Services</Link>
        </div>
        <div className={styles.feed}>
          <h3>TWEETS AND INSTA FEED</h3>
          <div className={styles.twitterFeed}>
            {/* <TwitterTimelineEmbed
              sourceType="profile"
              screenName="iamTMTY" // input your twitter handle here
              options={{height: 300}}
            /> */}
          </div>
          <div className={styles.instaFeed}></div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
