import React from 'react'
import styles from '../../sass/components/FAQ/Projects.module.scss'
import SectionHeader from '../SectionHeader'
import projectImg from '../../assets/images/projectImg.jpg'
import { Link } from 'react-router-dom'
import Border from '../../assets/icons/Border'

const Projects = () => {
  return (
    <div className={styles.pastProject}>
      <SectionHeader text="Past Projects" fontSize="1.5rem" />
      <div className={styles.projects}>
        <div className={styles.project}>
          <div className={styles.projectImg}>
            <img src={projectImg} alt="project" />
            <Border className={styles.bottomLeft} />
          </div>
          <div className={styles.projectInfo}>
            <h4>Lorem ipsum</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad</p>
          </div>
        </div>
        <div className={styles.project}>
          <div className={styles.projectImg}>
            <img src={projectImg} alt="project" />
            <Border className={styles.topRight} />
          </div>
          <div className={styles.projectInfo}>
            <h4>Lorem ipsum</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad</p>
          </div>
        </div>
        <div className={styles.project}>
          <div className={styles.projectImg}>
            <img src={projectImg} alt="project" />
            <Border className={styles.topLeft} />
          </div>
          <div className={styles.projectInfo}>
            <h4>Lorem ipsum</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad</p>
          </div>
        </div>
        <div className={styles.project}>
          <div className={styles.projectImg}>
            <img src={projectImg} alt="project" />
            <Border className={styles.bottomRight} />
          </div>
          <div className={styles.projectInfo}>
            <h4>Lorem ipsum</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad</p>
          </div>
        </div>
      </div>
      <div className={styles.partner}>
        <div className={styles.partnerDesc}>
          <h5>Become a partner</h5>
          <span> Form partner with us and become an accredited agent</span>
        </div>
        <Link to="/join">JOIN</Link>
      </div>
    </div>
  )
}

export default Projects
