import React from 'react'
import styles from '../../sass/components/About/Mission.module.scss'
import SectionHeader from '../SectionHeader'
import visionOne from '../../assets/images/visionOne.jpg'
import visionTwo from '../../assets/images/visionTwo.jpg'

const Mission = () => {
  return (
    <div className={styles.mission}>
      <SectionHeader text="Our Mission & Vision" fontSize="1.5rem" />
      <div className={styles.missionContent}>
        <div className={styles.description}>
            <p>
              <strong>Mission:</strong> Empowering Lives Through Seamless Integration
            </p>
        
            <p>
            At Mainlogix Technology, our mission is to forge a connected future where cutting-edge technology integrates seamlessly across domains, fostering interoperability, privacy, and empowerment.  We prioritize a user-centric approach, enhancing smart home management, healthcare accessibility, and seniors' autonomy. Our aim is to build smart communities that honor users’ privacy and elevate living standards. 
            </p>
            <br />
            <p>
              <strong>Vision: </strong>
              An Innovative IoT Future
            </p>
            <p>
              Mainlogix envisions a future where interoperability and IoT innovations connect various aspects of life, from healthcare to home automation. We aspire to empower individuals within smart communities, ensuring enhanced well-being, empowerment, and privacy through our cutting-edge solutions.
            </p>
        </div>
        <div className={styles.images}>
          <img src={visionTwo} alt="vision" />
          <img src={visionOne} alt="vision" />
        </div>
      </div>
    </div>
  )
}

export default Mission
