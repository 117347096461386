import React from 'react'
import styles from '../../sass/components/Products/ProductItem.module.scss'
import productItemOne from '../../assets/images/Grandeur_smart_Elderly_Smart_2.png'
import { Link } from 'react-router-dom'
const ProductItemElder = () => {
  return (
    <div className={styles.productItem}>
      <div className={styles.productDescription}>
        <h3>Smart Elderly Care Kit/Sensor</h3>
        <p>Proactive Health Monitoring: Continuous monitoring of vital signs and activity levels, ensuring timely intervention in case of health issues</p>
        <Link to="/">BUY NOW</Link>
      </div>
      <img src={productItemOne} alt="product item" />
    </div>
  )
}

export default ProductItemElder
