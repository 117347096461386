import React from 'react'
import styles from '../../sass/components/Services/Service.module.scss'
import SectionHeader from '../SectionHeader'
import serviceImageOne from '../../assets/images/education.svg'

const Service2 = () => {
  return (
    <div className={styles.service} >
      <div className={styles.serviceImg}>
        <img src={serviceImageOne} alt="service" />
      </div>
      <div className={styles.serviceDesc}>
        <SectionHeader text="Smart School" fontSize="1.5rem" />
        <p>
        The idea of Smart Schools is still very novel. If education is getting decentralized, all learners should at one point in the future be able to control the type of instruction they get and how it is shared with them.With MainLogix Smart school devices, the education systems will transform and improve. This will improve students academically while also improving teaching efficiency with improved security for both learner and learning environment.
        </p>
      </div>
    </div>
  )
}

export default Service2
