import React from 'react'
import {Link} from 'react-router-dom'
import styles from '../../sass/components/Home/HeroCarouselContent.module.scss'
const HeroCarouselGrandeur = () => {
  return (
    <div className={styles.content}>
      <h2>Grandeur smart</h2>
      <p>
        Grandeursmart is a Smart City and Home Automation company, That focuses on Automating securing of homes, City and Community with a wide range of smart devices.
      </p>
      <Link to="/products">GO TO SHOP</Link>
    </div>
  )
}

export default HeroCarouselGrandeur
