import React from 'react'

const LinkedIn = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.81171 4.90896C7.48558 4.09571 8.24776 3.68322 9.21142 3.73301C10.7792 3.81389 11.8826 4.95944 11.9619 6.74782C12.0316 8.32297 11.9822 9.90363 11.9929 11.4815C11.9955 11.8602 11.8266 12.0017 11.4803 11.9925C11.0166 11.9802 10.5511 11.969 10.0884 11.9973C9.62945 12.0255 9.45488 11.8321 9.46276 11.3536C9.48085 10.2824 9.4697 9.21032 9.46773 8.13867C9.46707 7.84663 9.47054 7.5532 9.44879 7.26254C9.38176 6.36289 8.87879 5.81993 8.13311 5.82603C7.3667 5.83223 6.74664 6.4787 6.73314 7.3388C6.71336 8.60498 6.72527 9.87165 6.72274 11.1381C6.72096 11.9861 6.7203 11.9861 5.92258 11.9916C4.19759 12.0032 4.19824 12.0032 4.19759 10.1923C4.19693 8.34137 4.20978 6.48992 4.18409 4.63935C4.17668 4.10348 4.3474 3.88887 4.85646 3.93896C5.15084 3.96799 5.44999 3.94221 5.74718 3.94348C6.63386 3.94762 6.63386 3.94811 6.81171 4.90896ZM0.180131 7.9339C0.180131 6.82378 0.199537 5.71317 0.1716 4.60393C0.158756 4.08518 0.347474 3.9056 0.82391 3.9364C1.2861 3.96612 1.75166 3.95313 2.21516 3.9428C2.56334 3.93522 2.72075 4.084 2.71944 4.46135C2.71119 6.79839 2.71006 9.13514 2.71437 11.4722C2.71503 11.8463 2.56091 12.0005 2.21038 11.9927C1.70966 11.9815 1.208 11.9774 0.707661 11.9948C0.323568 12.0083 0.179287 11.836 0.183412 11.4392C0.1956 10.2709 0.187725 9.10218 0.187725 7.93381C0.185381 7.9339 0.182756 7.9339 0.180131 7.9339ZM1.42335 2.78426C0.608849 2.78062 0.00556904 2.19191 3.78087e-05 1.39528C-0.00558717 0.612231 0.617099 0.000590707 1.42006 3.20943e-07C2.29381 -0.000491667 2.88556 0.564705 2.88209 1.39626C2.87834 2.24307 2.30553 2.7879 1.42335 2.78426Z" />
    </svg>
  )
}

export default LinkedIn
